import React from 'react';
import PropTypes from 'prop-types';

import AccountMigrationInfoHeader from './components/AccountMigrationInfoHeader';
import AccountMigrationInfoDescription from './components/AccountMigrationInfoDescription';

import './account-migration-info-modal.scss';

const AccountMigrationInfoModal = ({ onClose }) => {
  return (
    <div className="account-migration-info-modal-wrapper">
      <button onClick={onClose} className="account-migration-info-modal-close-button">
        <i className="fas fa-times" />
      </button>
      <AccountMigrationInfoHeader />
      <AccountMigrationInfoDescription />
    </div>
  );
};

AccountMigrationInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AccountMigrationInfoModal;
