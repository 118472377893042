import React from 'react';
import { FormattedMessage } from 'react-intl';

import infoCircle from '../../../../../../assets/img/info-circle.svg';

import '../account-migration-info-modal.scss';

const AccountMigrationInfoHeader = () => (
  <div className="account-migration-info-modal-header">
    <img src={infoCircle} className="icon" alt="Migration Info Banner" />

    <FormattedMessage id="accountMigrationInfoModal.header" defaultMessage="NIBE Uplink Data Transfer" />
  </div>
);

export default AccountMigrationInfoHeader;
