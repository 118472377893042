import React from 'react';
import { Button } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import { confirmDeleteUser } from '../../../../../api';
import { logoutUser } from '../../../Login/actions';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { eventEmitter, events as insightEvents } from '../../../../tracking/AppInsights';

const DeleteConfirmationForm = ({
  token,
  smallButtons,
  history,
  clearPrepopulatedSystems,
  clearUserSystemTiles,
  getUserSystems,
}) => {
  const handleAccountDeletion = async () => {
    if (!token) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
      return;
    }

    try {
      const response = await confirmDeleteUser(token);

      handleSuccessfulAccountDeletion(response);
    } catch (error) {
      handleFailedAccountDeletion(error);
    }
  };

  const handleSuccessfulAccountDeletion = async response => {
    if (response.status === 200) {
      NotificationManager.success(
        <FormattedMessage id="deleteAccount.success" defaultMessage="Your account was successfully deleted." />,
      );

      try {
        await handleUserSystems();
      } finally {
        logoutUser(false);
        history.push('/');
      }
    }
  };

  const handleFailedAccountDeletion = error => {
    if (error.response) {
      if (error.response.status === 400) {
        NotificationManager.error(
          <FormattedMessage
            id="user.delete.error.request.last.admin"
            defaultMessage="To delete your user account at myUplink, you need to first delete your Service Partner account at {myUplinkPro}. If you want to still use your account at myUplinkPro, please keep this user account at myUplink as well."
            values={{
              myUplinkPro: (
                <u
                  className="email-not-confirmed-message"
                  onClick={() => window.open(`${process.env.WEB_PRO_URL}/service-partner-settings/delete`, '_blank')}
                >
                  myUplinkPro
                </u>
              ),
            }}
          />,
          '',
          10000,
        );
      } else {
        NotificationManager.error(
          <FormattedMessage
            id="deleteAccount.failed"
            defaultMessage="Failed to delete the account. Please try again."
          />,
        );
      }
    } else {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    }
    eventEmitter.emit(insightEvents.track, error);
  };

  const handleUserSystems = async () => {
    const systems = await getUserSystems();

    if (systems) {
      await clearPrepopulatedSystems(systems);
      await clearUserSystemTiles(systems);
    }
  };

  return (
    <div className="form">
      <p>
        <FormattedMessage
          id="label.system-profile.delete-account-last-warning"
          defaultMessage="By deleting your personal account, you will lose all personal data and access to your system. This is the final step of deletion account and this action can not be undone."
        />
      </p>
      <div className="form__inputWrapper">
        <div className={`${smallButtons ? 'button-wrapper' : 'button-wrapper--large'}`}>
          <Button
            className={`button--danger ${!smallButtons && 'space-bottom'}`}
            type="button"
            id="back-to-login"
            onClick={handleAccountDeletion}
          >
            <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
          </Button>
          <Button className="button--default" type="button" onClick={() => history.push('/login')}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmationForm.propTypes = {
  token: PropTypes.string,
  smallButtons: PropTypes.bool,
  history: PropTypes.object.isRequired,
  clearPrepopulatedSystems: PropTypes.func,
  clearUserSystemTiles: PropTypes.func,
  getUserSystems: PropTypes.func,
};

export default DeleteConfirmationForm;
