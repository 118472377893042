import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import '../account-migration-info-modal.scss';

const AccountMigrationInfoDescription = () => (
  <div className="account-migration-info-modal-description">
    <FormattedMessage
      id="accountMigrationInfoModal.description1"
      defaultMessage="Now, you can migrate your old NIBE Uplink account with existing one, all of your device data and configuration will be transferred."
    />
    <FormattedMessage
      id="accountMigrationInfoModal.description2"
      defaultMessage="Follow {link} if you want to start the process. If not, migration will be available in the Profile Settings."
      values={{
        link: (
          <Link to="/profile-settings">
            <FormattedMessage id="accountMigrationInfoModal.profileSettingsLink" defaultMessage="this link" />
          </Link>
        ),
      }}
    />
  </div>
);

export default AccountMigrationInfoDescription;
