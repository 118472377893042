import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'jpi-cloud-web-ui-components';

import Spacer from '../../../../layout/Spacer';
import Divider from '../../../../layout/Divider/Divider';

import './account-migration-section.scss';

const AccountMigrationSection = ({ openMigrationModal }) => (
  <div className="account-migration-section">
    <Spacer y={50} />
    <div className="account-migration-section-divider-wrapper">
      <Divider />
    </div>
    <Spacer y={50} />
    <h4 className="account-migration-section-header">
      <FormattedMessage id="accountMigrationSection.title" defaultMessage="NIBE Uplink Migration" />
    </h4>
    <Spacer y={24} />
    <p className="account-migration-section-content">
      <FormattedMessage
        id="accountMigrationSection.description"
        defaultMessage="Here you can migrate your old NIBE Uplink account with existing one, all of your device data 
and configuration will be transferred."
      />
    </p>
    <Spacer y={50} />
    <Button className="button--secondary" onClick={openMigrationModal}>
      <FormattedMessage id="accountMigrationSection.migrateButton" defaultMessage="Start Migration" />
    </Button>
  </div>
);

AccountMigrationSection.propTypes = {
  openMigrationModal: PropTypes.func,
};

export default AccountMigrationSection;
