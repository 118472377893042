import { addLocaleData } from 'react-intl';

import messages_cs from './supported-languages/cs.json';
import messages_da from './supported-languages/da.json';
import messages_de from './supported-languages/de.json';
import messages_en from './supported-languages/en.json';
import messages_es from './supported-languages/es.json';
import messages_et from './supported-languages/et.json';
import messages_fi from './supported-languages/fi.json';
import messages_fr from './supported-languages/fr.json';
import messages_hu from './supported-languages/hu.json';
import messages_it from './supported-languages/it.json';
import messages_nl from './supported-languages/nl.json';
import messages_nb from './supported-languages/nb.json';
import messages_pl from './supported-languages/pl.json';
import messages_ro from './supported-languages/ro.json';
import messages_ru from './supported-languages/ru.json';
import messages_sk from './supported-languages/sk.json';
import messages_sl from './supported-languages/sl.json';
import messages_sv from './supported-languages/sv.json';
import messages_pt from './supported-languages/pt.json';
import messages_tr from './supported-languages/tr.json';
import messages_lt from './supported-languages/lt.json';
import messages_lv from './supported-languages/lv.json';

import legal_agreements_en from './legal-agreements/en.json';
import legal_agreements_de from './legal-agreements/de.json';
import legal_agreements_sv from './legal-agreements/sv.json';
import legal_agreements_es from './legal-agreements/es.json';

import locale_cs from 'react-intl/locale-data/cs';
import locale_da from 'react-intl/locale-data/da';
import locale_de from 'react-intl/locale-data/de';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import locale_et from 'react-intl/locale-data/et';
import locale_fi from 'react-intl/locale-data/fi';
import locale_fr from 'react-intl/locale-data/fr';
import locale_hu from 'react-intl/locale-data/hu';
import locale_it from 'react-intl/locale-data/it';
import locale_nl from 'react-intl/locale-data/nl';
import locale_nb from 'react-intl/locale-data/nb';
import locale_pl from 'react-intl/locale-data/pl';
import locale_ro from 'react-intl/locale-data/ro';
import locale_ru from 'react-intl/locale-data/ru';
import locale_sk from 'react-intl/locale-data/sk';
import locale_sl from 'react-intl/locale-data/sl';
import locale_sv from 'react-intl/locale-data/sv';
import locale_tr from 'react-intl/locale-data/tr';
import locale_pt from 'react-intl/locale-data/pt';
import locale_lt from 'react-intl/locale-data/lt';
import locale_lv from 'react-intl/locale-data/lv';

// TODO: Investigate whether d3-time-format can be replaced,
// (i.e. with moment), as it seems to lack required locales.
import d3Time_cs from 'd3-time-format/locale/cs-CZ';
import d3Time_da from 'd3-time-format/locale/da-DK';
import d3Time_de from 'd3-time-format/locale/de-DE';
import d3Time_en from 'd3-time-format/locale/en-US';
import d3Time_es from 'd3-time-format/locale/es-ES';
import d3Time_fi from 'd3-time-format/locale/fi-FI';
import d3Time_fr from 'd3-time-format/locale/fr-FR';
import d3Time_hu from 'd3-time-format/locale/hu-HU';
import d3Time_it from 'd3-time-format/locale/it-IT';
import d3Time_nl from 'd3-time-format/locale/nl-NL';
import d3Time_nb from 'd3-time-format/locale/nb-NO';
import d3Time_pl from 'd3-time-format/locale/pl-PL';
import d3Time_ru from 'd3-time-format/locale/ru-RU';
import d3Time_sv from 'd3-time-format/locale/sv-SE';
import d3Time_pt from 'd3-time-format/locale/pt-BR';
import d3Time_tr from 'd3-time-format/locale/tr-TR';

import { generateD3TimeLocales } from './utils';

const d3Time_et = generateD3TimeLocales({ messages: messages_et });
const d3Time_ro = generateD3TimeLocales({ messages: messages_ro });
const d3Time_sk = generateD3TimeLocales({ messages: messages_sk });
const d3Time_sl = generateD3TimeLocales({ messages: messages_sl });
const d3Time_lt = generateD3TimeLocales({ messages: messages_lt });
const d3Time_lv = generateD3TimeLocales({ messages: messages_lv });

addLocaleData([
  ...locale_cs,
  ...locale_da,
  ...locale_de,
  ...locale_en,
  ...locale_es,
  ...locale_et,
  ...locale_fi,
  ...locale_fr,
  ...locale_hu,
  ...locale_it,
  ...locale_nl,
  ...locale_nb,
  ...locale_pl,
  ...locale_ro,
  ...locale_ru,
  ...locale_sk,
  ...locale_sl,
  ...locale_sv,
  ...locale_pt,
  ...locale_tr,
  ...locale_lt,
  ...locale_lv,
]);

const cultures = {
  cs: {
    language: 'cs',
    dialects: ['cs', 'cs-CZ'],
    messages: messages_cs,
    d3TimeLocale: d3Time_cs,
    momentLocale: 'cs',
    languageTag: 'cs-CZ',
  },
  da: {
    language: 'da',
    dialects: ['da'],
    messages: messages_da,
    d3TimeLocale: d3Time_da,
    momentLocale: 'da',
    languageTag: 'da-DK',
  },
  de: {
    language: 'de',
    dialects: ['de', 'de-AT', 'de-DE', 'de-LI', 'de-LI', 'de-CH'],
    messages: { ...messages_de, ...legal_agreements_de },
    d3TimeLocale: d3Time_de,
    momentLocale: 'de-at',
    languageTag: 'de-DE',
  },
  en: {
    language: 'en',
    dialects: [
      'en',
      'en-AU',
      'en-BZ',
      'en-CA',
      'en-CB',
      'en-IE',
      'en-JM',
      'en-NZ',
      'en-PH',
      'en-ZA',
      'en-TT',
      'en-GB',
      'en-US',
      'en-ZW',
    ],
    messages: { ...messages_en, ...legal_agreements_en },
    d3TimeLocale: d3Time_en,
    momentLocale: 'en',
    languageTag: 'en-US',
  },
  es: {
    language: 'es',
    dialects: ['es', 'es-ES', 'es-MX'],
    messages: { ...messages_es, ...legal_agreements_es },
    d3TimeLocale: d3Time_es,
    momentLocale: 'es',
    languageTag: 'es-ES',
  },
  et: {
    language: 'et',
    dialects: ['et', 'et-EE'],
    messages: messages_et,
    d3TimeLocale: d3Time_et,
    momentLocale: 'et',
    languageTag: 'et-EE',
  },
  fi: {
    language: 'fi',
    dialects: ['fi', 'fi-FI'],
    messages: messages_fi,
    d3TimeLocale: d3Time_fi,
    momentLocale: 'fi',
    languageTag: 'fi-FI',
  },
  fr: {
    language: 'fr',
    dialects: ['fr', 'fr-BE', 'fr-CA', 'fr-FR', 'fr-LU', 'fr-MC', 'fr-CH'],
    messages: messages_fr,
    d3TimeLocale: d3Time_fr,
    momentLocale: 'fr',
    languageTag: 'fr-FR',
  },
  hu: {
    language: 'hu',
    dialects: ['hu', 'hu-HU'],
    messages: messages_hu,
    d3TimeLocale: d3Time_hu,
    momentLocale: 'hu',
    languageTag: 'hu-HU',
  },
  it: {
    language: 'it',
    dialects: ['it', 'it-IT'],
    messages: messages_it,
    d3TimeLocale: d3Time_it,
    momentLocale: 'it',
    languageTag: 'it-IT',
  },
  nl: {
    language: 'nl',
    dialects: ['nl', 'nl-BE', 'nl-NL'],
    messages: messages_nl,
    d3TimeLocale: d3Time_nl,
    momentLocale: 'nl',
    languageTag: 'nl-NL',
  },
  nb: {
    language: 'nb',
    dialects: ['nb', 'no', 'nn', 'nb-NO', 'nn-NO'],
    messages: messages_nb,
    d3TimeLocale: d3Time_nb,
    momentLocale: 'nb',
    languageTag: 'nb-NO',
  },
  pl: {
    language: 'pl',
    dialects: ['pl', 'pl-PL'],
    messages: messages_pl,
    d3TimeLocale: d3Time_pl,
    momentLocale: 'pl',
    languageTag: 'pl-PL',
  },
  ro: {
    language: 'ro',
    dialects: ['ro'],
    messages: messages_ro,
    d3TimeLocale: d3Time_ro,
    momentLocale: 'ro',
    languageTag: 'ro-RO',
  },
  ru: {
    language: 'ru',
    dialects: ['ru', 'ru-RU'],
    messages: messages_ru,
    d3TimeLocale: d3Time_ru,
    momentLocale: 'ru',
    languageTag: 'ru-RU',
  },
  sk: {
    language: 'sk',
    dialects: ['sk', 'sk-SK'],
    messages: messages_sk,
    d3TimeLocale: d3Time_sk,
    momentLocale: 'sk',
    languageTag: 'sk-SK',
  },
  sl: {
    language: 'sl',
    dialects: ['sl', 'sl-SI'],
    messages: messages_sl,
    d3TimeLocale: d3Time_sl,
    momentLocale: 'sl',
    languageTag: 'sl-SI',
  },
  sv: {
    language: 'sv',
    dialects: ['sv', 'sv-SE', 'sv-FI'],
    messages: { ...messages_sv, ...legal_agreements_sv },
    d3TimeLocale: d3Time_sv,
    momentLocale: 'sv',
    languageTag: 'sv-SE',
  },
  tr: {
    language: 'tr',
    dialects: ['tr', 'tr-TR'],
    messages: messages_tr,
    d3TimeLocale: d3Time_tr,
    momentLocale: 'tr',
    languageTag: 'tr-TR',
  },
  pt: {
    language: 'pt',
    dialects: ['pt', 'pt-BR'],
    messages: messages_pt,
    d3TimeLocale: d3Time_pt,
    momentLocale: 'pt',
    languageTag: 'pt-BR',
  },
  lt: {
    language: 'lt',
    dialects: ['lt', 'lt-LT'],
    messages: messages_lt,
    d3TimeLocale: d3Time_lt,
    momentLocale: 'lt',
    languageTag: 'lt-LT',
  },
  lv: {
    language: 'lv',
    dialects: ['lv', 'lv-LV'],
    messages: messages_lv,
    d3TimeLocale: d3Time_lv,
    momentLocale: 'lv',
    languageTag: 'lv-LV',
  },
};

export const getCultureByLanguage = requestedLanguage => {
  const cultureKey = Object.keys(cultures).find(key => cultures[key].dialects.includes(requestedLanguage));

  return (
    (cultures[cultureKey] && {
      ...cultures[cultureKey],
      messages: { ...cultures.en.messages, ...cultures[cultureKey].messages },
    }) ||
    cultures.en
  );
};
